<template>
  <div>
    <div class="my-2 adjust-left">
      <b-row>
        <b-col class="px-0">
          <div class="flex">
            <span class="f-red ml-1 mb-1">{{label}}</span>
            <div class="ml-2 badge badge-required">必須</div>
          </div>
          <b-input-group>
            <b-input-group-prepend
              class="mr-1 flex flexMiddle">
            </b-input-group-prepend>
            <b-form-textarea
              :class="!isLog && reasonData().errMessages.length > 0 ? 'errItem' : ''"
              :disabled="!writable"
              :maxLength="max"
              :rows="rows[initRow]"
              :placeholder="placeholder"
              :value="reasonData().reason"
              @input="kickSetText($event)"
              />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        v-if="!isOld && !isLog"
        class="ml-1">
        <p class="error my-1"
          v-for="(msg, index) in reasonData().errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div>
  </div>
<!--     <div
      v-for="(option, index) in options" :key="index"
      class="my-2 adjust-left">
      <b-row>
        <b-col class="px-0">
          <b-input-group>
            <b-input-group-prepend
              class="mr-1 flex flexMiddle">
              {{option.label}}
              {{option.prefix}}
            </b-input-group-prepend>
            <b-form-textarea
              :disabled="!writable"
              :class="!isLog && optionData(option.id).errMessages.length > 0 ? 'errItem' : ''"
              :maxLength="option.max"
              :rows="rows[option.view_template]"
              :value="!isLog ? optionData(option.id).field_answer_text
              : logData[`${fieldId}_${option.id}_1`].field_answer_text"
              @input="kickSetText($event, option.id)"
              :placeholder="option.placeholder"/>
            <b-input-group-append
              class="ml-1 flex flexMiddle">
              {{option.suffix}}
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row v-if="!isOld && !isLog"
        class="ml-1">
        <p class="error my-1"
          v-for="(msg, index) in optionData(option.id).errMessages" :key="index">
          {{msg}}
        </p>
      </b-row>
    </div> -->
</template>
<script>

export default {
  name: 'PartsApplicationFieldChangeReason',
  props: {
    fieldId: { type: Number },
    notice: {},
    writable: { type: Boolean, default: false },
    isOld: { type: Boolean, default: false },
    isLog: { type: Boolean, default: false },
    logData: { type: [Object, Array], default: () => [] },
    label: { type: String },
    max: { type: Number },
    initRow: { type: Number },
    placeholder: { type: String, default: '' },
  },
  data() {
    return {
      // viewTemplateに応じたrows テンプレートNo：行数
      rows: {
        1: 2,
        2: 4,
        3: 6,
        4: 8,
        5: 10,
      },
    };
  },
  methods: {
    kickSetText(value) {
      const param = {
        field_id: this.fieldId,
        value,
      };
      this.$emit('kickSetText', param);
    },
  },
  computed: {
    inputs() {
      if (this.isLog) {
        return [];
      }
      if (!this.isOld) {
        return this.$store.state.gasApplicationInput.changeReasonInputs;
      }
      return this.$store.state.gasApplicationOldInput.changeReasonInputs;
    },
    reasonData() {
      const self = this;
      return () => {
        const reasonData = self.inputs.find((input) => {
          const sameField = input.field_id === self.fieldId;
          return sameField;
        });
        return reasonData;
      };
    },
  },
};
</script>

<style scoped>
.errItem {
  border:solid 1px #dc3545 !important;
}
.error {
  font-size: 80%;
  color: #dc3545;
}
.adjust-left {
  margin-left: 9px;
}
.input-area {
  margin-left: -5px;
  display: block;
  flex-wrap: wrap;
}
.groupInputs {
  position: relative;
  width: 750px;
}
.badge-required {
  height: 21px !important;
}
.f-red {
  color: #CF2020;
}
</style>
