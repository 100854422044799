<template>
  <div>
    <b-row class="mt-2 mx-4 mb-2">
      <div class="inlineBlock label-wrap">
        <div class="label-text-wrap mb-0">
          <label v-if="fieldLabel === '留学先（予定）'" class="formLabel mb-0 bold">留学先</label>
          <label v-else class="formLabel mb-0 bold">{{ fieldLabel }}</label>
        </div>
        <div
          v-if="writable && canChangePlan && !isLinkedField(fieldId)">
          <!-- <div class="mr-2 badge badge-required">必須</div> -->
          <b-button
            :class="[
              isChange ? 'off-change' : 'on-change',
              'change-btn',
            ]"
            @click="switchChange()"
          >
            <span v-show="!isChange">変更する</span>
            <span v-show="isChange">変更取消</span>
          </b-button>
        </div>
        <div
          v-if="approvalRequired && canChangePlan"
          class="mt-2 notice-change">
          この項目の変更には、財団事務局の承認が必要です。
        </div>
        <div
          v-if="isLinkedField(fieldId) && change_messages.length > 0"
          class="mt-2 notice-change">
          <p
            class="mb-0 form-file-line"
            v-for="(data, index) in change_messages" :key="index">
              <span v-if="data.type === 1"
                :class="[
                  `message_${data.view_template}`,
                ]"
                v-html="data.message"></span>
          </p>
        </div>
      </div>
      <div class="mainWidth">
        <div
          v-if="isChange && fieldLabel !== '留学先（予定）'"
          class="mr-2 badge badge-optional">
          変更前
        </div>

        <b-row
          v-if="messages.length > 0"
          class="ml-1 mt-2">
          <p
            class="mb-0 form-file-line"
            v-for="(data, index) in messages" :key="index">
            <span v-if="data.type === 1"
              :class="[
                `message_${data.view_template}`,
              ]"
              v-html="data.message"></span>
            <span
              v-if="data.type === 3"
              :class="[
                `message_${data.view_template}`,
              ]">
              ダウンロード：
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </b-row>
        <div v-if="type !== 100" class="changeBefor1">
          <component
            :is="typeMap[type]"
            :fieldId='fieldId'
            :is_variable="is_variable"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :options.sync="options"
            :notice="notice"
            :writable="false"
            :view_template="view_template"
            :rules="rules"
            :messages="messages"
            :params="params"
            :downloadFormFile="downloadFormFile"
            :isOld="true"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :downloadApploadedFile="downloadApploadedFile"
            :nonePlace="true"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>
        <div v-if="type === 100 && fieldLabel !== '留学先（予定）'" class="changeBefor2">
          <component
            :is="specialConponentNameList[view_template]"
            :fieldId='fieldId'
            :options.sync="options"
            :notice="notice"
            :writable="false"
            :messages="messages"
            :params="params"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :isOld="true"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :nonePlace="true"
            :downloadFormFile="downloadFormFile"
            :downloadApploadedFile="downloadApploadedFile"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>

<!--         <div
          v-if="approvalRequired && canChangePlan && fieldLabel !== '助成金使途明細'"
          class="ml-1">
          <p class="mb-0 f-13">＊<span class="underline">助成申請時</span>に作成したエクセルファイルを基に作成してください。</p>
          <p class="mb-0 f-13"><span class="underline">エクセルファイルがない場合</span>には書式を本トップページよりダウンロードし作成してください。</p>
          <p class="mb-0 red-text f-13">＊修正の箇所は赤字で記してください。</p>
          <p class="mb-0 f-13">＊添付する際にはPDFに変換してください。</p>
        </div> -->
        <div
          v-else-if="messages.length > 0 && fieldLabel !== '留学先（予定）'"
          class="ml-1">
          <p
            v-for="(data, index) in messages" :key="index"
            class="mb-0">
            <!-- <b-col class="px-0" v-if="data.type === 2">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col>

            <b-col class="px-0" v-if="data.type === 4">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col> -->
            <!-- view_template:2→赤文字テキスト -->
            <span class="f-13"
              v-if="data.type === 2"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>
            <span class="f-13"
              v-if="data.type === 6"
              :class="`bottom-msg-view${data.view_template} blue-text`">{{data.message}}</span>
            <span class="f-13"
              v-if="data.type === 7"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>

            <span class="f-13 ml-3" v-if="data.type === 4">
              ダウンロード：{{data.report_type_asset_id}}
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </div>
        <div v-if="type === 6 && fieldLabel !== '助成金使途明細'">
          <p class="mb-0"><span class="f-13 bottom-msg-view2">＊A4縦でアップロードされていることをご確認ください。</span></p>
        </div>
        <div v-if="type === 6 && fieldLabel === '永住許可証明書'">
          <p class="mb-0"><span class="f-13 bottom-msg-view7">＊該当する場合は必ずご提出ください。</span></p>
        </div>
        <hr class="change" v-if="isChange && fieldLabel !== '留学先（予定）'">

        <div
          v-if="isChange && fieldLabel === '留学先（予定）'"
          class="mr-2 badge badge-additional-red">
          決定留学先
        </div>
        <div
          v-if="isChange && fieldLabel !== '留学先（予定）'"
          class="mr-2 badge badge-additional-red">
          変更後
        </div>
        <div
          v-if="isChange && canChangePlan && (label === '資金調達・助成金使途内訳')"
          class="ml-1">
          <p class="mb-0 mt-2 f-13 red-text">＊こちらを変更する際には必ず本ページ<span class="underline pointer font-is-bold" @click="movePageTop">トップ</span>より助成金使途変更申請.xlsxをダウンロードしてご利用ください。</p>
          <!-- <p class="mb-0 f-13"><span class="underline">エクセルファイルがない場合</span>には書式を本トップページよりダウンロードし作成してください。</p>
          <p class="mb-0 red-text f-13">＊修正の箇所は赤字で記してください。</p> -->
          <p class="mb-0 f-13 red-text">＊添付する際にはPDFに変換してください。</p>
        </div>
        <div
          v-if="isChange && canChangePlan && (label === '助成金使途明細')"
          class="ml-1">
          <p class="mb-0 mt-2 f-13 red-text">＊こちらを変更する際には必ず本ページ<span class="underline pointer font-is-bold" @click="movePageTop">トップ</span>より助成金使途変更申請.xlsxをダウンロードしてご利用ください。</p>
          <!-- <p class="mb-0 f-13"><span class="underline">エクセルファイルがない場合</span>には書式を本トップページよりダウンロードし作成してください。</p>
          <p class="mb-0 red-text f-13">＊修正の箇所は赤字で記してください。</p> -->
          <p class="mb-0 f-13 red-text">＊エクセルシートで提出してください。</p>
        </div>
        <div
          v-if="isChange && canChangePlan && change_messages.length > 0"
          class="ml-1">
          <p
            v-for="(data, index) in change_messages" :key="index"
            class="mb-0">
            <span class="f-13"
              v-if="data.type === 2"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>
            <span class="f-13"
              v-if="data.type !== 2 && data.type !== 1"
              :class="`bottom-msg-view${data.view_template}`">{{data.message}}</span>
          </p>
        </div>
        <b-row
          v-if="messages.length > 0 && isChange"
          class="ml-1 mt-2">
          <p
            class="mb-0 form-file-line"
            v-for="(data, index) in messages" :key="index">
            <span v-if="data.type === 1"
              :class="[
                `message_${data.view_template}`,
              ]"
              v-html="data.message"></span>
            <span
              v-if="data.type === 3"
              :class="[
                `message_${data.view_template}`,
              ]">
              ダウンロード：
              <b-link
                @click="downloadOpenFile(assetId(index), data.message)"
                class="mb-0">
                {{data.message}}
              </b-link>
            </span>
          </p>
        </b-row>
        <div v-if="type !== 100 && isChange" class="changeAfter1">
          <component
            :is="typeMap[type]"
            :fieldId='fieldId'
            :is_variable="is_variable"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :view_template="view_template"
            :rules="rules"
            :messages="messages"
            :params="params"
            :downloadFormFile="downloadFormFile"
            :isOld="false"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :isChangePlan="true"
            :nonePlace="true"
            :downloadApploadedFile="downloadApploadedFile"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>
        <div v-if="type === 100 && isChange" class="changeAfter2">
          <component
            :is="specialConponentNameList[view_template]"
            :fieldId='fieldId'
            :options.sync="options"
            :notice="notice"
            :writable="writable"
            :messages="messages"
            :params="params"
            :input_number="input_number"
            :min_input_number="min_input_number"
            :max_input_number="max_input_number"
            :isOld="false"
            :controlFormatter="controlFormatter"
            :controlInputType="controlInputType"
            :isChangePlan="true"
            :nonePlace="true"
            :downloadFormFile="downloadFormFile"
            :inNoneInput="inNoneInput"
            :downloadApploadedFile="downloadApploadedFile"
            @kickSetText="setText($event)"
            @kickSetSelect="setSelect($event)"
            @kickSetFile="setFile($event)"
            @kickDeleteFile="deleteFile($event)"
            />
        </div>
        <div
          v-else-if="isChange && messages.length > 0 && fieldLabel === '留学先（予定）'"
          class="ml-1">
          <p
            v-for="(data, index) in messages" :key="index"
            class="mb-0">
            <!-- <b-col class="px-0" v-if="data.type === 2">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col>

            <b-col class="px-0" v-if="data.type === 4">
              <p class="mb-0 f-13">{{data.message}}</p>
            </b-col> -->
            <span class="f-13" v-if="data.type === 2">{{data.message}}</span>
          </p>
        </div>
        <div v-if="isChangeReason && isChange">
          <v-app-change-reason
            :fieldId='fieldId'
            :notice="notice"
            :writable="writable"
            :isOld="false"
            label="変更理由"
            :max="100"
            :initRow="2"
            placeholder=""
            @kickSetText="setReason($event)"
          />
        </div>

        <!-- 変更履歴 -->

        <hr
          v-if="isChangeHistory && (changeHisLogs || firstLog)"
          class="change">
        <div
          v-if="isChangeHistory && (changeHisLogs || firstLog)"
          class="mr-2 badge badge-optional">
          変更履歴
        </div>
        <div v-if="firstLog">
          <p
            class="mb-0 mt-1"><span class="bold mr-2">計画変更申請前の初期データ</span> 登録日:{{firstLog.created_at}}</p>
          <div v-if="type !== 100" class="changeLogFContents1">
            <component
              :is="typeMap[type]"
              :fieldId='fieldId'
              :is_variable="is_variable"
              :input_number="input_number"
              :min_input_number="min_input_number"
              :max_input_number="max_input_number"
              :options.sync="options"
              :notice="notice"
              :writable="false"
              :view_template="view_template"
              :rules="rules"
              :messages="messages"
              :params="params"
              :downloadFormFile="downloadFormFile"
              :isOld="true"
              :isLog="true"
              :logData="firstLog"
              :controlFormatter="controlFormatter"
              :controlInputType="controlInputType"
              :downloadApploadedFile="downloadApploadedFile"
              />
          </div>
          <div v-else class="changeLogFContents2">
            <component
              :is="specialConponentNameList[view_template]"
              :fieldId='fieldId'
              :options.sync="options"
              :notice="notice"
              :writable="false"
              :messages="messages"
              :params="params"
              :input_number="input_number"
              :min_input_number="min_input_number"
              :max_input_number="max_input_number"
              :isOld="true"
              :isLog="true"
              :logData="firstLog"
              :controlFormatter="controlFormatter"
              :controlInputType="controlInputType"
              :nonePlace="true"
              :downloadFormFile="downloadFormFile"
              :downloadApploadedFile="downloadApploadedFile"
              />
          </div>
        </div>
        <div v-if="isChangeHistory && changeHisLogs">
          <div v-for="(changeLog, index) in changeHisLogs" :key="index" :data-num="index">
            <p
            class="mb-0 mt-1"><span class="bold mr-2">第{{changeLog.sort + 1}}回 変更元内容</span> 登録日:{{changeLog.created_at}}</p>
            <div v-if="type !== 100" class="changeLogContents1">
              <component
                :is="typeMap[type]"
                :fieldId='fieldId'
                :is_variable="is_variable"
                :input_number="input_number"
                :min_input_number="min_input_number"
                :max_input_number="max_input_number"
                :options.sync="options"
                :notice="notice"
                :writable="false"
                :view_template="view_template"
                :rules="rules"
                :messages="messages"
                :params="params"
                :downloadFormFile="downloadFormFile"
                :isOld="false"
                :isLog="true"
                :logData="changeLog"
                :controlFormatter="controlFormatter"
                :controlInputType="controlInputType"
                :downloadApploadedFile="downloadApploadedFile"
                />
            </div>
            <div v-else class="changeLogContents2">
              <component
                :is="specialConponentNameList[view_template]"
                :fieldId='fieldId'
                :options.sync="options"
                :notice="notice"
                :writable="false"
                :messages="messages"
                :params="params"
                :input_number="input_number"
                :min_input_number="min_input_number"
                :max_input_number="max_input_number"
                :isOld="false"
                :isLog="true"
                :logData="changeLog"
                :controlFormatter="controlFormatter"
                :controlInputType="controlInputType"
                :nonePlace="true"
                :downloadFormFile="downloadFormFile"
                :downloadApploadedFile="downloadApploadedFile"
                />
            </div>
          </div>
        </div>
      </div>
    </b-row>
  </div>
</template>
<script>
import Input from '@/components/parts/application/Input.vue';
import Textarea from '@/components/parts/application/Textarea.vue';
import Check from '@/components/parts/application/Check.vue';
import Radio from '@/components/parts/application/Radio.vue';
import File from '@/components/parts/application/File.vue';
import Table from '@/components/parts/application/Table.vue';
import Datetime from '@/components/parts/application/Datetime.vue';
import Selectbox from '@/components/parts/application/Selectbox.vue';
import Calendar from '@/components/parts/application/Calendar.vue';
import Collaborator from '@/components/parts/application/special/Collaborator_1.vue';
import Participant from '@/components/parts/application/special/Participant_2.vue';
import Destination from '@/components/parts/application/special/J_Destination_3.vue';
import Iterm from '@/components/parts/application/special/I_Term_4.vue';
import CONST_STATUS from '@/constants/specialField';
import ChangeReason from '@/components/parts/application/FieldChangeReason.vue';

export default {
  name: 'PartsApplicationText',
  components: {
    'v-app-input': Input,
    'v-app-textarea': Textarea,
    'v-app-check': Check,
    'v-app-radio': Radio,
    'v-app-file': File,
    'v-app-table': Table,
    'v-app-datetime': Datetime,
    'v-app-selectbox': Selectbox,
    'a-app-calendar': Calendar,
    'v-app-collaborator': Collaborator,
    'v-app-participant': Participant,
    'v-app-destination': Destination,
    'v-app-i-term': Iterm,
    'v-app-change-reason': ChangeReason,
  },
  props: {
    fieldId: { type: Number },
    fieldLabel: { type: String },
    description: { type: String, default: '' },
    notice: { type: String, default: '' },
    type: { type: Number },
    is_required: { type: [Boolean, Number], default: false },
    // 記入項目マスタ
    label: { type: String, default: '' },
    is_variable: { type: [Boolean, Number], default: true },
    input_number: { type: Number, default: 1 },
    min_input_number: { type: Number, default: 1 },
    max_input_number: { type: Number, default: 1 },
    view_template: { type: Number, default: 1 },
    writable: { type: Boolean, default: false },
    options: {
      type: Array,
      default: () => {
        return [
          {
            id: '',
            label: '',
            // input_type: '',
            prefix: '',
            suffix: '',
            placeholder: '',
            parent_option_id: null,
            parent_choice_id: null,
            view_template: 1,
            max: null,
          },
        ];
      },
    },
    assets: { type: [Object, Array], default: () => {} },
    rules: { type: [Object, Array], fedault: () => {} },
    messages: { type: [Object, Array], default: () => [] },
    change_messages: { type: [Object, Array], default: () => [] },
    params: { type: [Object, Array], default: () => [] },
    downloadFormFile: { type: Function },
    downloadOpenFile: { type: Function },
    isChange: { type: Boolean, default: false },
    approvalRequired: { type: [Boolean, Number], default: false },
    canChangePlan: { type: [Boolean, Number], default: false },
    isChangeReason: { type: [Boolean, Number], default: false },
    inNoneInput: { type: Boolean, default: false },
    isChangeHistory: { type: Boolean, default: false },
    linkFieldList: { type: String, default: null },
    linkedList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    changeHisLogs: {},
    firstLog: {},
    downloadApploadedFile: { type: Function },
  },
  data() {
    return {
      typeMap: {
        1: 'v-app-input',
        2: 'v-app-textarea',
        3: 'v-app-check',
        4: 'v-app-radio',
        5: 'v-app-selectbox',
        6: 'v-app-file',
        7: 'v-app-table',
        8: 'v-app-datetime',
        9: 'a-app-calendar',
      },
    };
  },
  created() {
    this.options.forEach((data, key) => {
      this.options[key].placeholder = '';
    });
    if (this.isChange) {
      if (this.linkFieldList) {
        const linkList = this.linkFieldList.split(',');
        linkList.forEach((linkFieldId) => {
          this.$emit('switchChange', linkFieldId);
          this.$emit('updateLinkedList', linkFieldId);
        });
      }
    }
  },
  methods: {
    movePageTop() {
      const Ease = {
        easeInOut: (t) => { return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; },
      };
      const duration = 500;
      // 現在のスクロール位置を取得（クロスブラウザに対応）
      const currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
      // スタート時点の時間を取得
      const startTime = performance.now();
      // スクロール先の要素の位置を取得
      const targetPosition = 0;
      // アニメーションのループを定義
      const loop = (nowTime) => {
        // スタートからの経過時間を取得
        const time = nowTime - startTime;
        // duration を1とした場合の経過時間を計算
        const normalizedTime = time / duration;
        // duration に経過時間が達していない場合はアニメーションを実行
        if (normalizedTime < 1) {
          // 経過時間とイージングに応じてスクロール位置を変更
          window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
          // アニメーションを継続
          requestAnimationFrame(loop);
        // duration に経過時間が達したら、アニメーションを終了
        } else {
          window.scrollTo(0, targetPosition);
        }
      };
      // アニメーションをスタート
      requestAnimationFrame(loop);
    },
    switchChange() {
      this.$emit('switchChange', this.fieldId);
      if (this.linkFieldList) {
        const linkList = this.linkFieldList.split(',');
        linkList.forEach((linkFieldId) => {
          this.$emit('switchChange', linkFieldId);
          this.$emit('updateLinkedList', linkFieldId);
        });
      }
    },
    controlInputType(ruleIdList) {
      if (ruleIdList.includes(17) || ruleIdList.includes(18)) {
        return 'tel';
      }
      return 'text';
    },
    controlFormatter(ruleIdList) {
      // 半角数字のみ可
      if (ruleIdList.includes(17)) {
        return this.onlyHalfSizeNumber;
      }
      if (ruleIdList.includes(18)) {
        return this.onlyHalfSizeDate;
      }
      return null;
    },
    onlyHalfSizeNumber(value) {
      return value.replace(/[^0-9]/g, '');
    },
    onlyHalfSizeDate(value) {
      return value.replace(/[^0-9/]/g, '');
    },
    setText(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setText', param);
    },
    setSelect(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setSelect', param);
    },
    setFile(param) {
      this.$store.commit('gasApplicationForm/setHasChange', true);
      this.$store.commit('gasApplicationInput/setFile', param);
    },
    setReason(param) {
      this.$store.commit('gasApplicationInput/setReason', param);
    },
    deleteFile(param) {
      this.$store.commit('gasApplicationInput/deleteFile', param);
    },
  },
  computed: {
    messagesEachType() {
      if (!this.messages) {
        return [];
      }
      const messagesEachType = [];
      this.messages.forEach((msgData) => {
        if (!messagesEachType[msgData.type]) {
          messagesEachType[msgData.type] = [];
        }
        const data = {
          msg: msgData.message,
          view_template: msgData.view_template,
        };
        messagesEachType[msgData.type].push(data);
      });
      return messagesEachType;
    },
    specialConponentNameList() {
      return CONST_STATUS.COMPONENT_NAMES;
    },
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    assetId() {
      return (index) => {
        if (this.messages[index].report_type_asset_id) {
          return this.messages[index].report_type_asset_id;
        }
        return this.messages[index].application_type_asset_id;
      };
    },
    isLinkedField() {
      return (fieldId) => {
        return this.linkedList.includes(String(fieldId));
      };
    },
  },
};
</script>

<style scoped>
  .font-is-bold{
    font-weight: bold;
  }
  .pointer{
    cursor:pointer;
  }
  .badge-additional-red{
    color: #dc3545;
    background-color: #FFF;
    border: 2px solid #dc3545;
    border-radius: 0px !important;
  }
  .titleWrap {
    padding: 8px 5px 8px 10px;
    border: 1px solid #ddd;
  }
  .head {
    background-color: #122889;
    width: 5px;
    height: 22px;
  }
  .title {
    font-size: 126%;
    line-height: 1.2em;
  }

  .formLabel {
    white-space: pre-line;
    top: 8px;
  }

  .label {
    width: 260px;
    max-width: 260px;
  }

  .mainWidth {
    width: 730px;
    max-width: 730px;
  }

  .fileNotice {
    font-size: 13px;
  }

  .message_1 {
    font-size: 13px;
  }

  .form-file-line {
    width: 100%;
  }

  .change-btn {
    margin-top: 10px;
    width: 100px;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: bold;
    background-color: #fff !important;
    border-radius: 18px;
  }

  .on-change {
    border: 2px solid #293CB0 !important;
    color: #293CB0 !important;
  }

  .off-change {
    border: 2px solid #8E8E8E !important;
    color: #8E8E8E !important;
  }

  hr.change {
    border-top: 3px double;
  }

  .label-wrap {
    width: 240px;
    margin-right: 30px;
  }

  .label-text-wrap {
    width: 240px;
  }

  .notice-change {
    font-size: 14px;
    color: #dc3545;
  }
  .label-msg-view2, .bottom-msg-view2 {
    color: #dc3545;
  }
  .label-msg-view7, .bottom-msg-view7 {
    color: #dc3545;
    font-weight: bold;
  }
</style>
